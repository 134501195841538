import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TaskReportComponent } from './modules/infogeon-task-management/task-report/task-report.component';
import { TaskBulkUploadComponent } from './modules/infogeon-task-management/task-bulk-upload/task-bulk-upload.component';
import { TaskTypeComponent } from './modules/infogeon-task-management/task-type/task-type.component';
import { TaskAdminComponent } from './modules/infogeon-task-management/task-admin/task-admin.component';

const routes: Routes = [
  { path: '', redirectTo: 'taskmanagement', pathMatch: 'full' },
  { path: 'taskmanagement', loadChildren: () => import('./modules/infogeon-task-management/infogeon-task-management.module').then(m => m.InfogeonTaskManagementModule) },
  { path: 'report', component: TaskReportComponent },
  { path: 'task-bulk-upload', component: TaskBulkUploadComponent },
  { path: 'kpi', loadChildren: () => import('./modules/infogeon-kpi/infogeon-kpi.module').then(m => m.InfogeonKpiModule) },
  { path: 'task-type', component: TaskTypeComponent },
  { path: 'kpi_mission', loadChildren: () => import('./modules/infogeon-kpi-mission/infogeon-kpi-mission.module').then(m => m.InfogeonKpiMissionModule) },
  { path: 'lpos', loadChildren: () => import('./modules/infogeon-lpos/infogeon-lpos.module').then(m => m.InfogeonLposModule) },
  { path: 'field_xchange', loadChildren: () => import('./modules/infogeon-field-xchange/infogeon-field-xchange.module').then(m => m.InfogeonFieldXchangeModule) },
  { path: 'field_xpert', loadChildren: () => import('./modules/infogeon-field-xpert/infogeon-field-xpert.module').then(m => m.InfogeonFieldXpertModule) },
  { path: 'dell_offer', loadChildren: () => import('./modules/infogeon-dell-offer/infogeon-dell-offer.module').then(m => m.InfogeonDellOfferModule) },
  { path: 'classroom', loadChildren: () => import('./modules/infogeon-classroom/infogeon-classroom.module').then(m => m.InfogeonClassroomModule) },
  { path: 'lc', loadChildren: () => import('./modules/infogeon-learning-champion/infogeon-learning-champion.module').then(m => m.InfogeonLearningChampionModule) },
  { path: 'reports', loadChildren: () => import('./modules/infogeon-reports/infogeon-reports.module').then(m => m.InfogeonReportsModule) },
  //{ path: 'admin', component: TaskAdminComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
